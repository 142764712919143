import React from 'react'
import Header from '../components/Header'
import { Footer } from '../components/Footer'
import { Link } from 'gatsby'

export default function NotFound() {
	return (
		<>
			<div className="container">

				<Header />

				<section>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
					<h1>Page Not Found</h1>
                    <h3><Link to={`/`}><ion-icon name="arrow-back"></ion-icon> Back Home</Link></h3>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
				</section>
			</div>

			<Footer />
		</>
	)
}